
















































































import { Component } from 'vue-property-decorator';
import WidgetMixins from '@/mixins/WidgetMixins';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { LoginState } from '@/types/types';
import { namespace } from 'vuex-class';

extend('email', {
  ...email,
  message: 'Email is not valid',
});
extend('required', {
  ...required,
  message: 'Field is required',
});

const authModule = namespace('auth');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    snackbar: loadWidget('widgets/SnackBar'),
  },
})
export default class Login extends mixins(WidgetMixins) {
  @authModule.Action('login') actionLogin: any;
  username: string = '';
  password: string = '';
  bg: string = require('@/assets/images/background-image.svg');
  show: boolean = false;

  authenticate(): void {
    let payload: LoginState = {
      username: this.username,
      password: this.password,
    };
    this.actionLogin(payload);
  }
}
